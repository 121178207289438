/** *************************************************************************************************
 * handsontable must be imported before Zone JS as described in the documentation.
 */
import 'handsontable';
/** *************************************************************************************************
 * Zone JS is required by Angular itself.
 */
import 'zone.js'; // Included with Angular CLI.

/** *************************************************************************************************
 * APPLICATION IMPORTS
 */

/**
 * Date, currency, decimal and percent pipes.
 * Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
 */
// import 'intl';  // Run `npm install --save intl`.
/**
 * Need to import at least one locale-data with intl.
 */
// import 'intl/locale-data/jsonp/en';

// for ng2-dragula
(window as any).global = window;

(window as any).process = {
    env: { DEBUG: undefined },
};
